<template>
  <article class="new-question">
    <div class="site-wrapper rythm-v-l">
      <header>
        <h1>Nouvelle question</h1>
      </header>

      <section class="section--new-question rythm-v">
        <header>
          <h2>Étape 1 – Thématiques</h2>
        </header>

        <div class="card shadow rythm-v" v-if="themeList">
          <form @submit.prevent="updateQuestionData()">
            <section class="themes-primary rythm-v">
              <header>
                <h3>Thématique générale <span class="required text-small">*</span></h3>
              </header>

              <div class="input-group--inline">
                <label v-for="theme in themesPrimary" :key="theme.id">
                  <input type="radio" name="primary_theme_id" :id="`primary_theme_id[${theme.id}]`" :value="theme.id" v-model="themes.primaryTheme" required />
                  <span class="caption">{{ theme.name }}</span>
                </label>
              </div>
            </section>

            <section class="themes-secondary rythm-v">
              <header>
                <h3>
                  Thématiques secondaires
                </h3>
              </header>

              <div class="input-group">
                <label v-for="theme in themesSecondary" :key="theme.id">
                  <input type="checkbox" name="secondary_themes" :id="`secondary_themes[${theme.id}]`" :value="theme.id" v-model="themes.secondaryThemes" />
                  <span class="caption">{{ theme.name }}</span>
                </label>
              </div>
            </section>

            <hr />

            <section class="flex-row flex-center">
              <div class="input-group">
                <label for="isUrgent">
                  <input type="checkbox" name="is_urgent" id="isUrgent" v-model="isUrgent" />
                  <span class="caption">Réponse urgente appréciée <small>(&lt;24h, ne pas en abuser)</small></span>
                </label>
              </div>
            </section>

            <section>
              <div class="input-group--inline-controls">
                <button class="button--primary" type="submit" :disabled="!themes.primaryTheme || !themes.secondaryThemes.length">
                  <span class="caption">Étape suivante</span>
                  <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683418 6.90237 1.31658 7.29289 1.70711L12.5858 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H12.5858L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </section>
          </form>
        </div>
      </section>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import "src/scss/question.scss";
</style>

<style lang="scss" scoped>
.themes-secondary .input-group > label {
  flex-basis: 20rem;
}
</style>

<script>
import buttonResetQuestion from "@/components/buttonResetQuestion";
import DropdownFilter from "@/components/DropdownFilter";

// Create component
export default {
  name: "questionEditThemes",

  components: { buttonResetQuestion, DropdownFilter },

  props: ["questionID"],

  data: function() {
    return {
      isDraft: false,
      isUrgent: false,

      // Themes
      themeList: [],
      themesPrimary: [],
      themesSecondary: [],

      // Question Themes
      themes: {
        // Radios
        primaryTheme: null,
        // checboxes
        secondaryThemes: [],
      },
    };
  },

  methods: {
    // Save local question data
    updateQuestionData: function() {
      let payload = {
        questionID: this.questionID,
        dataFragment: { themes: this.themes, isUrgent: this.isUrgent },
      };
      this.$store
        .dispatch("SAVE_QUESTION_DRAFT", payload)
        .then(() => this.$router.push(`/edit-question-details/${this.questionID}`))
        .catch((error) => {
          console.error("SAVE_QUESTION_DRAFT error");
        });
    },

    setupThemes: function(themeList) {
      // set the global tree and the root themes
      this.themeList = themeList;
      this.themesPrimary = themeList.filter((el) => el.type === "primary");
      this.themesSecondary = themeList.filter((el) => el.type === "secondary");
    },

    // Fetch the themes from the API
    getThemes: function() {
      this.$store
        .dispatch("GET_THEMES")
        .then((response) => {
          let themeList = response;
          this.setupThemes(themeList);
        })
        .catch((error) => {
          console.log("getThemes error…", error);
          // eventHub.$emit("network-error", error.response);
        });
    },
  },

  mounted() {
    // Fetch the full theme tree
    this.getThemes();

    // NOTE: no drafts/question edit for now. Reset local storage
    // this.$store.dispatch("RESET_QUESTION_DRAFT");

    // Check if there is a local draft to populate themes
    if (parseInt(this.questionID)) {
      let questionData = this.$store.state.questionDraft;
      if (null !== questionData && questionData.themes) {
        this.themes = questionData.themes;
        this.isDraft = true;
      }
    } else {
      this.$store.dispatch("RESET_QUESTION_DRAFT");
    }
    // if (!parseInt(this.questionID)) {
    // 	let questionData = this.$store.state.questionDraft;

    // 	if (null !== questionData && questionData.themes) {
    // 		this.themes = questionData.themes;
    // 		this.isDraft = true;
    // 	}
    // }
  },
};
</script>
